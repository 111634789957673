import { Insight } from 'types/Insight';

export const insightSelectors = {
  companyIdSelector(insight: Insight) {
    return insight.companyId;
  },
  domainId(insight: Insight) {
    return insight.domainId;
  },
  riskProfileTokenSelector(insight: Insight) {
    return insight.riskProfileToken;
  },
  merchantIdSelector(insight: Insight) {
    return insight.merchantId;
  },
  isPartiallyPaidSelector(insight: Insight) {
    return insight.isPartiallyPaid;
  },
  offerIdSelector(insight: Insight) {
    return insight.offerId;
  },
  offeringIdSelector(insight: Insight) {
    return insight.offeringId;
  },
  isFullyPaidSelector(insight: Insight) {
    return insight.isFullyPaid;
  },
  isPayableSelector(insight: Insight) {
    return insight.isPayable;
  },
  isPayEnabledSelector(insight: Insight) {
    return insight.isPayEnabled;
  },
  tokenSelector(insight: Insight) {
    return insight.token;
  },
  openInvoicesSelector() {
    return [];
  }, // open invoices is not implemented yet in sale refactor
  view2PaySelector(insight: Insight) {
    return insight.view2pay;
  },
  paymentProcessorSelector(insight: Insight) {
    return insight.paymentProcessor;
  },
  shouldShowSchedulePaySelector(insight: Insight) {
    return insight.shouldShowSchedulePay;
  },
  shouldShowAutopaySelector(insight: Insight) {
    return insight.shouldShowAutopay;
  },
  paymentDueStatusSelector(insight: Insight) {
    return insight.paymentDueStatus;
  },
  b2bPayorVendorsSelector(insight: Insight) {
    return insight.vendors;
  },
  b2bPayorAccountsSelector(insight: Insight) {
    return insight.accounts;
  },
  b2bPayorRealmIdSelector(insight: Insight) {
    return insight.payorRealmId;
  },
  isPayorBusiness(insight: Insight): boolean {
    return insight?.payorSegmentation?.isBusiness;
  },
  isFirstTimeInCP(insight: Insight): boolean {
    return insight?.payorSegmentation?.isFirstTimeInCP;
  },
  isDebitCardEnabled(insight: Insight): boolean {
    return insight?.debitCardEnabled;
  },
  hasEverPaidSelector(insight: Insight): boolean {
    return !!insight?.payorSegmentation.previousPaymentDate;
  },
};
