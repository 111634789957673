import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import FeedbackModal from './FeedbackModal/FeedbackModal';
import { isInvoiceInFeedbackExperiment } from './feedback.utils';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const logger = 'pages/Feedback';
import { RootState } from 'store/store';
import { fontSize, colors, breakpoints } from 'styles/cp';

const splunkReporter = SplunkReporter.getInstance();
export const SUCCESS_SCREEN_LINK_URI = 'https://feedback.qbo.intuit.com/forums/591862';
export const MAIN_PAGE_LINK_URI = 'https://feedback.qbo.intuit.com/forums/920104';

export interface FeedbackLinkProps {
  sectionName?: 'success-screen' | 'main-section';
  transactionType?: 'INVOICE' | 'PAYMENT_REQUEST' | string;
  showFeedbackForm: boolean;
}

function mapFeedbackLinkStateToProps(state: RootState) {
  return {
    showFeedbackForm: isInvoiceInFeedbackExperiment(state),
  };
}

const _FeedbackLink: React.FC<FeedbackLinkProps> = ({
  sectionName = 'main-section',
  transactionType = 'INVOICE',
  showFeedbackForm,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);

  let classNames = 'feedback-link',
    link = MAIN_PAGE_LINK_URI;

  if (sectionName === 'success-screen') {
    classNames += ' always-show';
    link = SUCCESS_SCREEN_LINK_URI;
  }

  if (transactionType === 'PAYMENT_REQUEST') {
    classNames += ' payment-request';
  }

  if (showFeedbackForm) {
    return (
      <React.Fragment>
        <a
          className={classNames}
          href="#"
          rel="noopener noreferrer"
          onClick={() => setShowFeedback(true)}
        >
          <FormattedMessage
            id="INVOICE_FEEDBACK"
            defaultMessage="Tell us how we can make paying an invoice better?"
          />
        </a>
        {showFeedback && <FeedbackModal onHide={() => setShowFeedback(false)} />}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <a className={classNames} target="_blank" rel="noopener noreferrer" href={link}>
          <FormattedMessage
            id="INVOICE_FEEDBACK"
            defaultMessage="Tell us how we can make paying an invoice better?"
          />
        </a>

        <style jsx>{`
          .feedback-link {
            display: block;
            text-decoration: none;
            margin: 15px 0 15px;
            font-size: ${fontSize.xs};
            color: ${colors.blue};
            text-align: center;

            @media screen and (max-width: ${breakpoints.sm}) {
              font-size: ${fontSize.xxs};
            }

            &.always-show {
              @media screen and (max-width: ${breakpoints.md}) {
                margin: 15px 37px;
              }
            }

            &.payment-request {
              display: none;
            }

            a,
            a:visited,
            a:hover,
            a:active {
              text-decoration: none;
              color: inherit;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
};

export const FeedbackLink = connect(mapFeedbackLinkStateToProps)(_FeedbackLink);

export interface FeedbackButtonProps {
  transactionType?: 'INVOICE' | 'PAYMENT_REQUEST';
  showFeedbackForm: boolean;
}

const _FeedbackButton: React.FC<FeedbackButtonProps> = (props: FeedbackButtonProps) => {
  const { transactionType = 'INVOICE', showFeedbackForm } = props;
  const onClick = () => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'feedbackLink',
      action: 'click',
      activityInfo: {
        activityObject: 'feedbackButton',
      },
    });
  };

  const [showFeedback, setShowFeedback] = useState(false);
  return (
    <React.Fragment>
      <div
        className={`${
          transactionType === 'PAYMENT_REQUEST' ? 'payment-request' : ''
        } feedback-w desktop`}
      >
        {!showFeedbackForm ? (
          <a href={MAIN_PAGE_LINK_URI} rel="noopener noreferrer" target="_blank" onClick={onClick}>
            <span>Feedback</span>
          </a>
        ) : (
          <a
            rel="noopener noreferrer"
            data-testid="feedbackv2btn"
            onClick={() => setShowFeedback(true)}
          >
            <span>Feedback</span>
          </a>
        )}
      </div>
      {showFeedback && <FeedbackModal onHide={() => setShowFeedback(false)} />}
      <style jsx>{`
        .payment-request {
          display: none;
        }

        .feedback-w.desktop {
          position: fixed;
          z-index: 999;
          transform-origin: bottom;
          transform: translate(50%, -100%) rotate(-90deg);
          right: 0px;
          bottom: 48px;
          height: 40px;
          line-height: 40px;

          a {
            background: ${colors.gray06};
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border: solid 1px ${colors.gray06};
            text-decoration: none;
            color: ${colors.gray03};
            cursor: pointer;
            height: 40px;
            width: 125px;
            font-size: ${fontSize.sm};
            line-height: 40px;
            padding: 10px 25px;

            &:active {
              color: ${colors.gray03};
            }
          }

          @media screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }

        .feedback-w.mobile {
          text-align: center;
          margin: 15px 0 10px;
          font-size: ${fontSize.xs};

          @media not screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

function mapStateToProps(state: RootState) {
  return {
    showFeedbackForm: isInvoiceInFeedbackExperiment(state),
  };
}

export const FeedbackButton = connect(mapStateToProps)(_FeedbackButton);
