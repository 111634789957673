import React, { useEffect, useState } from 'react';

import DropdownOption, { DropdownOptionType } from './DropdownOption/DropdownOption';

import Chevron from 'components/Shared/Icons/Chevron';
import Input from 'components/Shared/Inputs/Input';
import useComponentVisible from 'hooks/useComponentVisible';
import { colors } from 'styles/cp';

export interface DropdownProps {
  options?: DropdownOptionType[];
  disabled?: boolean;
  placeholder?: string;
  isMobile?: boolean;
  onSelectOption: (option: DropdownOptionType) => void;
}

const Dropdown = (props: DropdownProps) => {
  const { options, onSelectOption, disabled, placeholder = '', isMobile } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOptionType>({
    name: '',
    issuerId: '',
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const {
    ref: optionsRef,
    isComponentVisible: areOptionsVisible,
    setIsComponentVisible,
  } = useComponentVisible(false, toggleDropdown);

  useEffect(() => {
    setIsComponentVisible(true);
  }, [isOpen]);

  const handleSelectOption = (option: DropdownOptionType) => {
    setSelectedOption(option);
    toggleDropdown();
    onSelectOption(option);
    setIsComponentVisible(false);
  };

  return (
    <React.Fragment>
      <div className="dropdown-container">
        <Input
          className="dropdown-input"
          data-testid="dropdown-input"
          onClick={toggleDropdown}
          readOnly
          disabled={disabled}
          value={selectedOption.name || placeholder}
        >
          <span className="dropdown-icon">
            <Chevron rotate={0} height={8} width={20} color={colors.gray03} />
          </span>
        </Input>
        {isOpen && areOptionsVisible && (
          <ul ref={optionsRef} className="dropdown-options-list">
            {options?.map((option: DropdownOptionType) => (
              <DropdownOption
                key={option.issuerId}
                option={option}
                onClick={() => handleSelectOption(option)}
                isMobile={isMobile}
              />
            ))}
          </ul>
        )}
      </div>
      <style jsx>
        {`
          .dropdown-container {
            position: relative;
          }
          .dropdown-input {
            border: ${isOpen ? `1px solid ${colors.green}` : 'none'};
          }
          .dropdown-options-list {
            border-radius: 4px;
            box-shadow: 0px 1px 4px 1px ${colors.gray05};
            background-color: white;
            padding: 4px 0px;
            font-size: 16px;
            list-style: none;
            margin-top: 1px;
            border: 1px solid ${colors.green04};
          }
          .dropdown-icon {
            padding: 5px;
            border-left: 1px solid ${colors.gray05};
            background-color: ${isOpen ? colors.gray06 : 'none'};
          }
        `}
      </style>
    </React.Fragment>
  );
};
export default Dropdown;
