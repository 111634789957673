import React, { Fragment, useEffect, useState } from 'react';

import { FreeTextAnalyticsFieldType } from './hooks/useNpeFreeTextAnalytics';

import { AlertIconQbo } from 'components/Shared/Icons/Icons';
import Input, { InputProps } from 'components/Shared/Inputs/Input';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import SegmentIO from 'reporting/SegmentIO';
import { ContactInfoType } from 'shared/types';
import { colors, fontSize as fontSizes } from 'styles/cp';

export interface ValidatedInputProps extends InputProps {
  validationFunction(value: string, valueType?: string): boolean;

  valueType?: ContactInfoType;
  additionalClassNames?: string;
  onChangeHandler: (
    onChangeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => any;
  isTextArea?: boolean;
  errorMessage?: string;
  enableTracking?: boolean;
  ariaLabel?: string;
}

const sendFieldEditEvent = (field: FreeTextAnalyticsFieldType, value?: string | undefined) => {
  // User edited form field
  // @ts-ignore
  SegmentIO.widgetEngaged({
    object: 'transaction',
    object_detail: 'single_invoice_transaction',
    action: 'engaged',
    ui_object: 'modal',
    ui_object_detail: 'send_message_to_merchant_form',
    ui_action: 'typed',
    ui_access_point: 'transaction_flow',
    b2b_data: {
      updated_field: value && field.toLowerCase(),
    },
  });
};

export const ValidatedInputQboStyle: React.FC<ValidatedInputProps> = (props) => {
  const {
    validationFunction,
    value,
    valueType,
    onChangeHandler,
    isTextArea = false,
    type,
    fontSize,
    inputCustomStyling,
    placeholder,
    required,
    errorMessage,
    enableTracking,
    ariaLabel,
    label,
    disabled = false,
  } = props;
  const isMobile = useIsMobileScreen(768);
  const LabelElement = label ? <InputLabel label={label} /> : null;
  const [isInError, setIsInError] = useState<boolean>(false);
  // only capture first type event per input-field, per session.
  const [isTrackEventSentOnce, setIsTrackEventSentOnce] = useState<boolean>(false);

  const validateInput = (): void => {
    const isInputValid: boolean = validationFunction(value || '', valueType || undefined);
    // wait until the user finished his mouseclick before displaying the error message
    setTimeout((): void => {
      setIsInError(!isInputValid);
    }, 100);
  };

  const handleOnBlur = () => {
    validateInput();
    if (enableTracking && value && !isTrackEventSentOnce) {
      sendFieldEditEvent(type as FreeTextAnalyticsFieldType, value);
      setIsTrackEventSentOnce(true);
    }
  };

  useEffect(() => {}, [isInError]);

  useEffect(() => {
    value && validateInput();
  }, [errorMessage]);

  return (
    <>
      {isTextArea ? (
        <>
          {LabelElement}
          <textarea
            value={value}
            onBlur={handleOnBlur}
            onFocus={() => {
              setIsInError(false);
            }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onChangeHandler(e);
            }}
            className={'modal-form-textarea'}
            placeholder={placeholder}
            disabled={disabled}
          ></textarea>
        </>
      ) : (
        <>
          {LabelElement}
          <Input
            theme={'qbo'}
            fontSize={fontSize || fontSizes.sm}
            inputCustomStyling={inputCustomStyling}
            placeholder={placeholder as string}
            type={type}
            required={required}
            value={value}
            onBlur={handleOnBlur}
            onFocus={() => {
              setIsInError(false);
            }}
            isInError={isInError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeHandler(e);
            }}
            ariaLabel={ariaLabel}
            disabled={disabled}
          />
        </>
      )}
      {isInError && (
        <span className={'modal-form-input-error-container'}>
          <AlertIconQbo width={isMobile ? 17 : 22} height={isMobile ? 21 : 22} />
          <span className={'modal-form-input-error-message'}>
            {errorMessage || 'Invalid input'}
          </span>
        </span>
      )}
      {/* language=scss */}
      <style jsx>
        {`
          .modal-form-input-error-container {
            display: flex;
            flex-direction: row;
            align-items: end;
            color: ${colors.gray01};
          }

          .modal-form-input-error-message {
            display: flex;
            flex-direction: column;
            height: 20px;
            justify-items: start;
            font-size: 14px;
            margin-top: 5px;
            margin-left: 5px;
            color: ${colors.error};
          }

          .modal-form-textarea {
            font-family: AvenirNextforINTUIT-Regular;
            border: 1px solid ${colors.gray05};
            font-size: 16px;
            width: 100%;
            align-items: start;
            padding: 8px 0px 0px 8px;
            display: flex;
            border-radius: 4px;
            min-height: ${isMobile ? '60px' : '80px'};
            resize: none;
            border: 1px solid ${!isInError ? colors.gray05 : colors.error};
            background-color: ${!isInError ? '' : 'rgba(255, 0, 0, 0.09)'};

            :focus-within {
              outline: #2ca01c auto 1px;
            }

            :hover {
              outline: none;
            }

            ::placeholder {
              font-size: 16px;
              color: ${colors.gray01};
            }
          }
        `}
      </style>
    </>
  );
};

const InputLabel: React.FC<InputLabelProps> = (props) => {
  const { label } = props;
  return (
    <Fragment>
      <label>{label}</label>
      <style jsx>{`
        label {
          font-family: AvenirNextforINTUIT-Demi;
          line-height: 20px;
          font-size: ${fontSizes.xxs};
          color: ${colors.darkGray};
          display: block;
        }
      `}</style>
    </Fragment>
  );
};

interface InputLabelProps {
  label: string | React.ReactNode;
}
