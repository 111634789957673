import HttpClient from 'server/helpers/HttpClient';
import { getWeakTicket } from 'shared/clientUtils';
import { RootState } from 'store/store';
import { handlePbbResponse, pbbOrderData } from 'types/BusinessLogic';
const httpClient = HttpClient.getInstance();

export const handlePbbOrder = async (state: RootState) => {
  const { payment, sale, config, auth, insight } = state;
  const { selectedBank } = payment;
  const {
    contact: { displayName },
    amount,
    type,
    isGpu,
    currencyInfo: { currency },
  } = sale;
  const { domainId, token } = insight;
  const { portal, ssrtid } = config;
  const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = auth;
  const headers = getWeakTicket({
    domainId,
    entityId,
    realmId,
    token,
    ticket,
    isUserSignedIn,
    syncToken,
    authToken,
    ssrtid,
  });

  if (amount < 1) {
    throw new Error('cannot pay amount < 1');
  }

  const data: pbbOrderData = {
    payment: {
      method: 'PAYBYBANK',
      amount: amount.toFixed(2),
      currencyCode: currency,
    },
    sale: {
      type,
      isGpu: isGpu ?? false,
    },
    payByBankDetail: {
      issuerBankId: selectedBank?.bankId,
      issuerBankName: selectedBank?.bankName,
    },
    contact: {
      name: displayName,
    },
  };

  headers['Authorization'] = `Bearer ${authToken}`;
  const handlePbbOrderResponse: handlePbbResponse = await httpClient({
    url: `/${portal}/rest/pbb/create-order`,
    endpoint: `/${portal}/rest/pbb/create-order`,
    method: 'POST',
    data,
    headers,
    timeout: 60000,
  });
  return handlePbbOrderResponse.data;
};
