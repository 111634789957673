import { isNumeric } from 'shared/utils';
import { RootState } from 'store/store';
import { Payment } from 'types/Payment';
import { Wallet } from 'types/Wallet';

export const walletSelectors = {
  userWallets(wallet: Wallet) {
    return wallet?.userWallets;
  },
  enabledPaymentMethods(wallet: Wallet) {
    return wallet?.enabledPaymentMethods;
  },
  isPaymentUsingWallet(state: RootState) {
    const { wallet = {} as Wallet, payment = {} as Payment } = state;
    const { selectedWalletId } = wallet;
    const { isSavePaymentMethodChecked } = payment;

    const isPaymentUsingWallet = isNumeric(selectedWalletId) || isSavePaymentMethodChecked;
    return isPaymentUsingWallet;
  },
  isApplePayEnabled(wallet: Wallet) {
    return wallet?.enabledPaymentMethods.includes('ap');
  },
};
