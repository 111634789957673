import type { Sale } from 'types/Sale';
import type { Wallet } from 'types/Wallet';

import { CPFeedbackFormValues } from 'components/Shared/Feedback/FeedbackForm/FeedbackForm';
import HttpClient from 'server/helpers/HttpClient';
import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { walletSelectors } from 'store/wallet/selectors';
import { Insight } from 'types/Insight';

const httpClient = HttpClient.getInstance();

export const handleSubmitFeedback = async (
  data: CPFeedbackFormValues,
  feedbackInfo: FeedbackData
) => {
  const { portal, sale, insight, authToken, realmId, ssrtid, wallet } = feedbackInfo;

  const id = saleSelectors.idSelector(sale);
  const amount = saleSelectors.amountSelector(sale);
  const txnDate = saleSelectors.txnDateSelector(sale);
  const merchantId = insightSelectors.merchantIdSelector(insight);
  const token = insightSelectors.tokenSelector(insight);
  const isPayorBusiness = insightSelectors.isPayorBusiness(insight);
  const isFirstTimeInCP = insightSelectors.isFirstTimeInCP(insight);
  const view2Pay = insightSelectors.view2PaySelector(insight);
  const debitCardEnabled = insightSelectors.isDebitCardEnabled(insight);
  const isApplePayEnabled = walletSelectors.isApplePayEnabled(wallet);
  const currency = saleSelectors.currencySelector(sale);
  const email = saleSelectors.toEmailsSelector(sale);
  const { isBankAndCard, isBankEnabled, isBankOnly, isCardEnabled, isCardOnly, isPayPalEnabled } =
    view2Pay;

  const hasEverPaid = insightSelectors.hasEverPaidSelector(insight);

  const payorEmail = email ? email[0] : '';

  const feedbackData = {
    ...data,
    saleId: id,
    merchantId,
    amount,
    txnDate,
    currency,
    email: payorEmail,
    hasEverPaid,
    isPayorBusiness,
    isFirstTimeInCP,
    debitCardEnabled,
    isPayPalEnabled,
    isBankAndCard,
    isBankEnabled,
    isBankOnly,
    isCardEnabled,
    isCardOnly,
    isApplePayEnabled,
  };

  await httpClient({
    url: `/${portal}/rest/feedback`,
    method: 'POST',
    data: feedbackData,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      ssrtid: ssrtid,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': token,
    },
    endpoint: `/${portal}/rest/feedback`,
  });
  return 'SENT_SUCCESSFULLY';
};

interface FeedbackData {
  sale: Sale;
  insight: Insight;
  authToken: string;
  realmId: string;
  ssrtid: string;
  portal: string;
  wallet: Wallet;
}
