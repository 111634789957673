import { getPaymentMethodsOrder, getCookieByName } from 'shared/clientUtils';
import deviceInfo from 'server/helpers/deviceInfo';
import { TXN_MAP } from 'types/constants';

import getState from 'store/utils/getState';
import { saleSelectors } from 'store/sale/selectors';
import { getFeatureEligibility, getScreenPrevious, getLinkSource } from './helpers';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { concatPaymentMethodWithSubType } from 'businessLogic/Payment/helpers';
import { ViewType } from 'shared/types';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'reporting/SegmentIO.js';
/**
 * @type {import('./SegmentIO')}
 */
const SegmentIO = {
  /**
   *
   * @param {{transactionType: string, ssrtid: string}} param0
   */
  init({
    offeringId,
    ivid,
    token,
    ssrtid,
    cpVersion,
    schema,
    domainId,
    companyId,
    isUserSignedIn,
    recipientEmail,
    ticket,
    merchantId,
    openInvoices,
    receivable,
    amount,
    region,
    type: transactionType,
    subType,
    entityId,
    isPayable,
    shouldShowSchedulePay,
    shouldShowAutopay,
    subscriptionPaymentsSetting,
    ixp,
    type,
  }) {
    try {
      const balanceAmount = receivable ? receivable.balance : null;
      const invoiceDueDate = receivable ? receivable.dueDate : null;
      const { navigator, document, location } = window;
      const event_timestamp_on_device = new Date();
      ivid = ivid ? ivid : ssrtid;
      const domain_id = domainId ? domainId.substring(domainId.indexOf(':') + 1) : null;
      const trackingApplicationName = 'sbseg-cpp';

      const total_invoices_amount_view = this.openInvoicesHelpers.getTotalInvoiceAmountView(
        openInvoices,
        balanceAmount
      );
      const invoice_due_date = this.openInvoicesHelpers.getInvoiceDueDate(
        openInvoices,
        invoiceDueDate
      );
      const invoice_amount = this.openInvoicesHelpers.getInvoiceAmount(openInvoices, amount);

      let product_name;
      if (typeof offeringId === 'string') {
        product_name = offeringId.toLowerCase();
      }
      const mandatoryFields = {
        // Mandatory fields
        tracking_application_name: trackingApplicationName,
        tracking_application_version: cpVersion,
        event_schema_id: schema,
        browser_height: window.innerHeight,
        browser_width: window.innerWidth,
        browser_cookies_enabled: navigator.cookieEnabled,
        browser_encoding: document.characterSet,
        // browser_java_enabled: navigator.javaEnabled(),
        url_query_param: location.search,
        url_host_name: location.hostname,
        // platform: navigator.platform,
        event_timestamp_on_device,
        ivid,
        merchant_country: region,
      };

      const commonData = {
        intuit_acstoken: token,
        ssrtid,
        transaction_type:
          (subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE && subType.toLowerCase()) ||
          (typeof transactionType === 'string' && transactionType.toLowerCase()),
        ...this.getTransactionId({
          transaction_type: transactionType,
          domain_id,
          payment_request_id: token,
        }),
        invoice_type: this.getInvoiceType({
          transactionType,
          subType,
          subscriptionPaymentsSetting,
          shouldShowSchedulePay,
          shouldShowAutopay,
          type,
        }),
        invoice_due_date,
        is_user_signedIn: isUserSignedIn,
        merchant_id: merchantId,
        recipient_email: recipientEmail, // is this sensitive?
        session_ticket: ticket,
        company_id: companyId,
        product_name,
        total_invoices_amount_view,
        invoice_amount,
        offeringId,
        payor_auth_id: entityId,
        payable: isPayable,
        pseudonym_id:
          schema === 'sbseg_cp_payments_prod'
            ? getCookieByName('qbn.uidp')
            : getCookieByName('qbn.ptc.uidp'),
      };

      SegmentIO.ixp = ixp;
      SegmentIO.commonData = { ...commonData, ...mandatoryFields };
      SegmentIO.additionalData = {};
    } catch (e) {
      SegmentIO.ixp = {};
      SegmentIO.commonData = {};
      SegmentIO.additionalData = {};
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'initSegmentIOCommonData',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: e.stack,
          message: e.message,
        },
      });
    }
  },

  /**
   * @param {{transaction_type: string, domain_id: string, payment_request_id: string}} param0
   */
  getTransactionId({ transaction_type, domain_id, payment_request_id }) {
    switch (transaction_type || TXN_MAP.TYPES.INVOICE) {
      case TXN_MAP.TYPES.PAYMENT_REQUEST:
        return { domain_id: null, payment_request_id };
      case TXN_MAP.TYPES.INVOICE:
      case TXN_MAP.TYPES.ESTIMATE:
      default:
        return { domain_id, payment_request_id: null };
    }
  },

  getInvoiceType({ transactionType, shouldShowSchedulePay, shouldShowAutopay, subType, type }) {
    let invoiceType = transactionType;
    if (type === 'SUBSCRIPTION') {
      invoiceType = 'SUBSCRIPTION';
    } else if (shouldShowSchedulePay || shouldShowAutopay) {
      invoiceType = shouldShowAutopay ? 'AP' : 'SP';
    } else if (subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE) {
      invoiceType = TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE;
    }
    return invoiceType;
  },

  setIsUserSignedIn(isUserSignedIn) {
    SegmentIO.commonData['is_user_signedIn'] = isUserSignedIn;
  },

  getCommonData() {
    return SegmentIO.commonData || {};
  },

  estimateAction({ insight, action }) {
    const eventName = 'estimate_action_cp';
    const additionalData = {
      domain_id: insight.domainId,
      pay_enabled: insight.isPayEnabled,
      action,
    };

    SegmentIO.trackWithCommonData(eventName, additionalData);
  },

  invoiceViewedEvent(payload) {
    const {
      receivable: { balance: balanceAmount },
      currencyInfo: { currency },
      isFullyPaid,
      isPartiallyPaid,
      realmId,
      lines: lineItems,
      username,
      txnDate,
      isPayEnabled: pay_enabled,
      paymentMethodType,
      view2pay: { isBankEnabled, isCardEnabled, isBankAndCard, isPayPalEnabled },
      enabledPaymentMethods,
      paymentDetailMessage,
      ixp,
      viewType = ViewType.WEB,
    } = payload;

    const invoiceStatus = isFullyPaid
      ? 'fully paid'
      : isPartiallyPaid
      ? 'partial Paid'
      : 'not paid';

    let pymt_type_allowed;
    if (isBankAndCard) {
      pymt_type_allowed = 'both';
    } else if (isBankEnabled) {
      pymt_type_allowed = 'bank';
    } else if (isCardEnabled) {
      pymt_type_allowed = 'cc';
    } else if (isPayPalEnabled) {
      pymt_type_allowed = 'pp';
    }
    const additionalData = {
      event_action: 'viewed',
      event_object: 'invoice',
      event_name: 'viewed:invoice',
      event_full: 'sbseg|prod|cp|invoice_dashboard|viewed:invoice',
      screen_path: 'invoice/invoice_dashboard',
      ui_action: 'viewed',
      ui_object: 'screen',
      ui_access_point: null,
      status: 'success',
      pay_enabled,
      pymt_type_allowed,
      remaining_balance: balanceAmount,
      credit_amount: -balanceAmount,
      username: username,
      invoice_inlines: lineItems,
      currency,
      invoice_status: invoiceStatus,
      user_realm: realmId,
      invoice_creation_date: txnDate,
      pm_landed:
        typeof paymentMethodType === 'string'
          ? paymentMethodType.replace(',', '-')
          : paymentMethodType,
      payment_methods_order: getPaymentMethodsOrder({
        enabledPaymentMethods,
      })
        .map((pm) => pm.replace(',', '-'))
        .join(','),
      ...generateExpectedAdditionalDataTestNames(ixp),
      test_group: null,
      viewType,
    };
    if (paymentDetailMessage) {
      additionalData.test_name.push('pay_now');
      additionalData.test_group = 'test';
    }
    SegmentIO.trackWithCommonData('Invoice_view_in_cp', additionalData);
  },

  /**
   * Track Invoice Error upstream
   * @param {{error: {status: number, token: string, ssrtid: string, initiator: string, intuit_tid: string, message: string}}} payload
   */
  invoiceErrorEvent(payload) {
    if (payload) {
      const { error } = payload;
      if (error) {
        const additionalData = {
          error_code: error.status || 500,
          error_description: error.message || 'missing error message',
          error_initiator: error.initiator || 'missing error initiator',
          intuit_acstoken: error.token || 'missing intuit_acstoken',
          intuit_tid: error.intuit_tid || 'missing intuit_tid',
        };

        SegmentIO.trackWithCommonData('Invoice_error_in_cp', additionalData);
      }
    }
  },
  autopaySignInClicked(payload) {
    const additionalData = {
      event_name: 'autopay_experiment_sign_in_clicked:invoice',
      event_action: 'viewed',
      event_object: 'invoice',
      event_full: 'sbseg|prod|cp|invoice_dashboard|autopay_experiment_sign_in_clicked:invoice',
      screen_path: 'invoice/invoice_dashboard',
      ui_action: 'viewed',
      ui_object: 'screen',
      ui_access_point: null,
      status: 'success',
      experimentId: payload.experimentId,
      treatment: payload.treatmentKey,
      treatmentPayload: payload.treatmentPayload,
    };

    SegmentIO.trackWithCommonData('autopay_sign_in_clicked', additionalData);
  },
  viewRequestChanges() {
    const additionalData = {
      object: 'transaction',
      object_detail: 'crm_request_change_estimate',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      action: 'viewed',
      ui_object: 'button',
      ui_object_detail: 'request_changes',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.trackWithCommonData('transaction:viewed', additionalData);
  },
  clickRequestChanges() {
    const additionalData = {
      object: 'transaction',
      object_detail: 'crm_request_change_estimate',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      action: 'engaged',
      ui_object: 'button',
      ui_object_detail: 'request_changes',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.trackWithCommonData('transaction:engaged', additionalData);
  },
  clickViewInvoiceEvent(lazyFetch) {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|invoice_dashboard|goto:screen',
      event_action: 'goto',
      screen_path: 'invoice/full_invoice',
      event_object: 'screen',
      event_object_detail: 'full_invoice',
      ui_action: 'click',
      ui_object: 'button',
      ui_object_detail: 'view_invoice',
      lazy_pdf_fetch: lazyFetch,
    };

    SegmentIO.transactionEngaged({
      ui_object: 'button',
      ui_object_detail: 'view_invoice_pdf',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });

    SegmentIO.trackWithCommonData('click_view_invoice', additionalData);
  },

  clickPrint(screen_path, lazyFetch) {
    const additionalData = {
      event_name: 'print:invoice',
      event_full: `sbseg|prod|cp|${screen_path}|print:invoice`,
      screen_path,
      event_action: 'print',
      event_object: 'invoice',
      event_object_detail: '',
      ui_action: 'click',
      ui_object: 'button',
      ui_object_detail: 'print',
      lazy_pdf_fetch: lazyFetch,
    };

    SegmentIO.trackWithCommonData('click_print_invoice', additionalData);
  },

  clickDownload(screen_path, lazyFetch) {
    const additionalData = {
      event_name: 'download:invoice',
      event_full: `sbseg|prod|cp|${screen_path}|download:invoice`,
      screen_path,
      event_action: 'download',
      event_object: 'invoice',
      event_object_detail: '',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'download',
      lazy_pdf_fetch: lazyFetch,
    };

    SegmentIO.trackWithCommonData('click_download_invoice', additionalData);
  },

  clickSignIn(ui_object, ui_access_point) {
    const additionalData = {
      event_name: 'started:oii_login',
      event_full: `sbseg|prod|cp|invoice_dashboard|started:oii login`,
      event_action: 'started',
      event_object: 'oii_login',
      event_object_detail: '',
      ui_action: 'clicked',
      ui_object,
      ui_object_detail: 'sign_in',
      ui_access_point,
    };

    SegmentIO.trackWithCommonData('click_sign_in', additionalData);
  },

  logBillEvent(payorRealmId, eventAction) {
    const ui_action = eventAction === 'viewed' ? eventAction : 'clicked';
    const ui_access_point = eventAction === 'viewed' ? 'success_screen' : 'button';
    const ui_object_detail =
      eventAction === 'viewed'
        ? 'log_bill_viewed'
        : eventAction === 'submit'
        ? 'add_to_quickbooks'
        : 'ok_lets_go';
    const additionalData = {
      event_name: `${eventAction}:log_bill_payment`,
      event_action: eventAction,
      event_object: 'log_bill_payment',
      event_object_detail: '',
      ui_action,
      ui_object_detail,
      ui_access_point,
      recipient_company_id: payorRealmId,
    };

    SegmentIO.trackWithCommonData(`${eventAction}:log_bill_payment`, additionalData);
  },

  clickTrust() {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|invoice_dashboard|goto:screen',
      event_action: 'goto',
      event_object: 'screen',
      event_object_detail: 'trust',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'trust',
    };

    SegmentIO.trackWithCommonData('click_trust_button', additionalData);
  },

  clickTermsOfService() {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|invoice_dashboard|goto:screen',
      screen_path: 'invoice/invoice_dashboard',
      event_action: 'goto',
      event_object: 'screen',
      event_object_detail: 'terms_of_service',
      ui_action: 'click',
      ui_object: 'link',
      ui_object_detail: 'terms_of_service',
      ui_access_point: 'null',
    };

    SegmentIO.trackWithCommonData('click_terms_of_service_link', additionalData);
  },

  clickPrivacy() {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|invoice_dashboard|goto:screen',
      screen_path: 'invoice/invoice_dashboard',
      event_action: 'goto',
      event_object: 'screen',
      event_object_detail: 'privacy',
      ui_action: 'click',
      ui_object: 'link',
      ui_object_detail: 'privacy',
    };

    SegmentIO.transactionEngaged({
      ui_object: 'button',
      ui_object_detail: 'privacy_statement',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });

    SegmentIO.trackWithCommonData('click_privacy_link', additionalData);
  },

  clickShowOrHideDetails(isOpen) {
    const additionalData = {
      event_name: `${isOpen ? 'view' : 'hide'}:invoice`,
      event_full: 'sbseg|prod|cp|receipt|click:invoice',
      event_action: `${isOpen ? 'view' : 'hide'}`,
      event_object: 'invoice',
      ui_action: 'click',
      ui_object: 'link',
      ui_object_detail: `${isOpen ? 'view_invoice_details' : 'hide_invoice_details'}`,
    };

    SegmentIO.trackWithCommonData('view_invoice_summary', additionalData);
  },

  clickContactInformation() {
    const additionalData = {
      event_name: 'open:contact_information',
      event_full: 'sbseg|prod|cp|invoice_dashboard|open:contact_information',
      event_action: 'open',
      event_object: 'contact_information',
      event_object_detail: 'contact_information',
      ui_action: 'click',
      ui_object: 'card',
      ui_object_detail: 'contact_information',
    };

    SegmentIO.trackWithCommonData('click_contact_information', additionalData);
  },

  changePaymentMethod(paymentMethodType, newPaymentMethodType) {
    const additionalData = {
      original_pm: paymentMethodType,
      pm_change_to: newPaymentMethodType,
      event_name: 'change:payment_method',
      event_full: 'sbseg|prod|cp|invoice_dashboard|change:payment_method',
      event_action: 'change',
      event_object: 'payment method',
      ui_action: 'click',
      ui_object: 'button',
      ui_object_detail: newPaymentMethodType,
    };

    SegmentIO.trackWithCommonData('change_payment_method', additionalData);
  },
  //ACH Reporting
  clickSavePaymentMethod(is_checked) {
    SegmentIO.additionalData.savePM = is_checked;
  },

  clickPayNow({
    paymentMethodType,
    payment_amount,
    balanceAmount: remaining_balance,
    currency,
    isFullyPaid,
    isPartiallyPaid,
    cardType,
    accountType,
    bankCode,
    userWallets,
    enabledPaymentMethods,
    openInvoices,
    ixp,
    paypalExpressCheckoutPaymentMethod,
  }) {
    const is_pm_saved =
      SegmentIO.additionalData && SegmentIO.additionalData.savePM
        ? SegmentIO.additionalData.savePM
        : null;
    const invoiceStatus = isFullyPaid
      ? 'fully paid'
      : isPartiallyPaid
      ? 'partial Paid'
      : 'not paid';
    const have_pm_in_wallet = userWallets && userWallets.length > 0;

    const selectedInvoices = openInvoices ? openInvoices.filter((invoice) => invoice.selected) : [];
    const num_invoices_selected = selectedInvoices.length + 1;
    const total_invoices_amount_selected = selectedInvoices
      ? selectedInvoices.reduce(
          (initialValue, invoice) => initialValue + invoice.balance,
          remaining_balance
        )
      : payment_amount;

    const additionalData = {
      payment_method: paypalExpressCheckoutPaymentMethod
        ? paypalExpressCheckoutPaymentMethod
        : paymentMethodType,
      payment_amount,
      remaining_balance,
      currency,
      card_type: cardType || null,
      account_type: accountType || null,
      routing_number: bankCode || null,
      invoiceStatus,
      is_pm_saved,
      pm_landed:
        typeof paymentMethodType === 'string'
          ? paymentMethodType.replace(',', '-')
          : paymentMethodType,
      payment_methods_order: getPaymentMethodsOrder({
        enabledPaymentMethods,
      })
        .map((pm) => pm.replace(',', '-'))
        .join(','),
      event_name: 'start:payment',
      event_full: 'sbseg|prod|cp|invoice_dashboard|start:payment',
      screen_path: 'payment',
      event_action: 'submit',
      event_object: 'payment',
      event_object_detail: 'pay_now',
      ui_action: 'click',
      ui_object: 'button',
      ui_object_detail: 'pay_now',
      have_pm_in_wallet,
      num_invoices_selected,
      total_invoices_amount_selected,
      ...generateExpectedAdditionalDataTestNames(ixp),
    };

    SegmentIO.trackWithCommonData('pay_start', additionalData);
  },

  paymentResult({
    status,
    paymentMethod,
    paymentMethodType,
    amountPaid,
    gratuity = null,
    savePaymentMethodSuccessful,
    trackingId,
    balanceAmount,
    currency,
    isFullyPaid,
    isPartiallyPaid,
    invoiceDueDate,
    paidInvoices,
    paymentDetailMessage,
    ixp,
    paymentType,
    scheduleActive,
  }) {
    const is_pm_saved_successfully =
      SegmentIO.additionalData && SegmentIO.additionalData.savePM
        ? savePaymentMethodSuccessful
        : null;
    const invoiceDate = new Date(invoiceDueDate);
    const dateToday = new Date();
    // compute the difference between today and the invoice due date in days
    const edd_delta = Math.ceil((invoiceDate.getTime() - dateToday.getTime()) / (1000 * 3600 * 24));
    const invoiceStatus = isFullyPaid
      ? 'fully paid'
      : isPartiallyPaid
      ? 'partial Paid'
      : 'not paid';
    const is_wallet_payment = paymentMethod && paymentMethod.indexOf('WALLET') > -1 ? true : false;
    const num_invoices_paid = paidInvoices ? paidInvoices.length : status ? 1 : 0;
    const additionalData = {
      num_invoices_paid,
      status,
      event_name: 'submit:server_response',
      event_full: `sbseg|prod|cp|${status ? 'receipt' : 'payment'}|submit:server_response`,
      // status (boolean:  paymentStatus === SUCCESS)
      screen_path: `${status ? 'receipt' : 'payment'}`,
      payment_method: paymentMethodType,
      is_wallet_payment,
      payment_amount: amountPaid,
      remaining_balance: balanceAmount,
      is_pm_saved_successfully,
      currency,
      transaction_id: trackingId,
      invoiceStatus,
      edd_delta,
      // status (boolean:  paymentStatus === SUCCESS)
      event_action: 'submitted',
      event_object: 'server_response',
      ui_action: 'viewed',
      // status (boolean:  paymentStatus === SUCCESS)
      ui_object: `${status ? 'screen' : 'error'}`,
      ...generateExpectedAdditionalDataTestNames(ixp),
      test_group: null,
      paymentType,
      schedule_active: scheduleActive,
    };

    if (paymentDetailMessage) {
      additionalData.test_name.push('pay_now');
      additionalData.test_group = 'test';
    }

    if (gratuity !== null) {
      additionalData.tip_amount = gratuity;
    }

    SegmentIO.trackWithCommonData('pay_result', additionalData);
  },

  clickDownloadReceipt() {
    const additionalData = {
      event_name: 'download:receipt',
      event_full: 'sbseg|prod|cp|receipt|download:receipt',
      screen_path: 'receipt',
      event_action: 'download',
      event_object: 'receipt',
      event_object_detail: 'download_receipt',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'download_receipt',
      ui_access_point: 'receipt',
    };

    SegmentIO.trackWithCommonData('click_download_receipt', additionalData);
  },

  clickBackToInvoice() {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|receipt|goto:screen',
      event_action: 'goto',
      event_object: 'screen',
      event_object_detail: 'invoice',
      ui_action: 'click',
      ui_object: 'button',
      ui_object_detail: 'back_to_invoice',
    };

    SegmentIO.trackWithCommonData('click_back_to_invoice', additionalData);
  },

  clickFeedbackLink() {
    const additionalData = {
      event_name: 'goto:screen',
      event_full: 'sbseg|prod|cp|receipt|goto:screen',
      screen_path: 'receipt',
      event_action: 'goto',
      event_object: 'screen',
      event_object_detail: 'buyer_opinion',
      ui_action: 'clicked',
      ui_object: 'link',
      ui_object_detail: 'how_can_we_make_paying_invoices_better',
    };

    SegmentIO.trackWithCommonData('click_how_can_we_make_paying_invoices_better', additionalData);
  },

  viewBlockedBrowser(payable) {
    const additionalData = {
      url: window.location,
      payable,
      event_full: 'sbg|prod|qbo|Payments/paylink-page: Unsupported browser',
    };

    SegmentIO.trackWithCommonData('view_blocked_browser', additionalData);
  },

  viewBlockedBrowserProceed(payable) {
    const additionalData = {
      url: window.location,
      payable,
      event_full: 'sbg|prod|qbo|Payments/paylink-page|Unsupported browser: proceed',
    };

    SegmentIO.trackWithCommonData('view_blocked_browser_proceed', additionalData);
  },

  signInSchedule() {
    SegmentIO.trackWithCommonData('signin_schedule');
  },

  setupSchedule() {
    SegmentIO.trackWithCommonData('setup_schedule');
  },

  cancelSchedule() {
    SegmentIO.trackWithCommonData('cancel_schedule');
  },

  openInvoicesNumberOfUnpaid(unpaid) {
    SegmentIO.trackWithCommonData('num_unpaid_invoice', { num_unpaid_invoice: unpaid });
  },

  surveyClicked() {
    SegmentIO.trackWithCommonData('survey_clicked');
  },

  gratuityClicked(selected) {
    const values = ['5%', '10%', '15%', 'other', 'none'];
    SegmentIO.transactionEngaged({
      activity_type: 'tips',
      ui_object: 'radio_button',
      ui_action: 'enabled',
      ui_access_point: 'transaction_flow',
      ui_object_detail: values[selected - 1], // align to array index
    });
  },

  gratuityOtherAmount(amount) {
    SegmentIO.transactionEngaged({
      activity_type: 'tips',
      ui_object: 'form_field',
      ui_action: 'typed',
      ui_access_point: 'transaction_flow',
      ui_object_detail: amount,
    });
  },

  clickViewOpenInvoice({ invoiceId, source }) {
    const commonData = SegmentIO.getCommonData();

    if (!source) {
      source = commonData.payable ? 'invoice_view' : 'invoice_paid';
    }

    const additionalData = {
      newInvoice: invoiceId,
      source,
    };

    SegmentIO.trackWithCommonData('click_unpaid_invoice', additionalData);
  },

  /**
   * A common event to track various flows for Canada bank payments feature.
   *
   * flowName - flowName for the flow being tracked
   * flowType - flowType/kind of the flow being tracked
   * flowStatus - flowStatus of the flow being tracked, it have to be one of these: 'started'|'cancelled'|'errored'|'completed'
   * action - action for the flow being tracked
   * action_details - action details (string or object) for the flow being tracked
   */
  trackCanadaBankPaymentsFlow({ flowName, flowStatus, action, action_details }) {
    const additionalData = {
      flowType: 'nanopay_cp',
      flowName,
      flowStatus,
      action,
      action_details,
    };

    SegmentIO.trackWithCommonData('canada_bank_payments', additionalData);
  },

  cpViewed(properties = {}) {
    const { event } = properties;
    const additionalData = {
      screen_path: 'checkout_&_shopping_cart/cp_success_page',
      org: 'sbseg',
      purpose: 'prod',
      scope: 'counterpart_portal',
      scope_area: 'checkout_&_shopping_cart',
      screen: 'cp_checkout_confirmation_page',
      ui_access_point: 'transaction_flow',
      ...properties,
      ...generateExpectedAdditionalDataTestNames(SegmentIO.ixp),
      ...deviceType(),
      ...resolution(),
    };

    SegmentIO.trackWithCommonData(event, additionalData);
  },

  async initTaxonomyV2(store) {
    try {
      const {
        sale,
        insight,
        companyInfo,
        auth,
        payment = {},
        ixp,
        wallet = {},
        featureFlags = {},
        config,
      } = store;

      const { txnDate, type, receivable } = sale;

      const { region, currency } = companyInfo;

      const {
        offeringId,
        token,
        isPayable,
        isPayEnabled,
        isFullyPaid,
        isPartiallyPaid,
        companyId,
        domainId,
        payorSegmentation,
      } = insight;

      const { ssrtid } = config;

      checkIfSegmentScriptLoadError(featureFlags);

      const domain_id = domainId ? domainId.substring(domainId.indexOf(':') + 1) : null;

      const { isUserSignedIn, recipientEmail } = auth;

      const { balanceAmount } = payment;

      const { enabledPaymentMethods } = wallet;

      SegmentIO.invoice_type = type ? type.toLowerCase() : 'unknown';

      const invoiceStatus = isFullyPaid
        ? 'fully paid'
        : isPartiallyPaid
        ? 'partial Paid'
        : 'not paid';

      const invoiceDueDate = receivable ? receivable.dueDate : null;

      const payment_methods_order = getPaymentMethodsOrder({
        enabledPaymentMethods,
      })
        .map((pm) => pm.replace(',', '-'))
        .join(',');

      const test_name = ixp
        ? Object.values(ixp)
            .filter((test) => test.treatmentKey !== 'CP_CONFIG_DEFAULT')
            .map((test) => `${test.experimentId}:${test.treatmentKey}`)
            .join(',')
        : '';
      const newTaxonomyData = {
        browser_height: window.innerHeight,
        browser_width: window.innerWidth,
        platform: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
        ivid: ssrtid,
        product_name: offeringId,
        feature_eligibility: getFeatureEligibility(store),
        screen_previous: getScreenPrevious(),
        invoice_type: SegmentIO.invoice_type,
        object_detail: SegmentIO.invoice_type,
        company_id: companyId,
        currency: currency,
        domain_id,
        intuit_acstoken: token,
        invoice_amount: balanceAmount,
        invoice_creation_date: txnDate,
        invoice_due_date: invoiceDueDate,
        invoice_status: invoiceStatus,
        is_user_signedin: isUserSignedIn,
        merchant_country: region,
        pay_enabled: isPayEnabled,
        payable: isPayable,
        pseudonym_id: SegmentIO.commonData.pseudonym_id,
        recipient_email: recipientEmail,
        offering_id: offeringId,
        payment_methods_order,
        page_experience: isPayable ? 'transaction_view' : 'view_only_access', // TODO
        invoicing_experience: !isPayable
          ? 'nonPayEnabled'
          : saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale)
          ? 'ACHOnlineConvenienceFee'
          : 'payEnabled',
        test_name,
        personalization_details: payorSegmentation,
        link_source: getLinkSource(),
      };

      SegmentIO.commonDataV2 = newTaxonomyData;
    } catch (e) {
      SegmentIO.commonDataV2 = {};
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'initTaxonomyV2',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: e.stack,
          message: e.message,
        },
      });
    }
  },

  getCommonDataV2() {
    return SegmentIO.commonDataV2;
  },

  getBaseTaxonomyProps() {
    return {
      ...SegmentIO.commonDataV2,
      org: 'sbseg',
      purpose: 'prod',
      scope: 'counterpart_portal',
      scope_area: 'checkout_&_shopping_cart',
      screen: 'cp_checkout',
      object: 'transaction',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
    };
  },

  getPaymentProps() {
    const state = getState();
    if (!state.payment) {
      return {};
    }
    const {
      sale,
      payment: {
        isDateScheduled,
        isAutoPayOn,
        gratuityValue = 0,
        amount,
        paymentMethodType,
        paymentMethodSubType,
        country,
        lastSuccessfulPaymentResponse,
      },
      wallet: { userWallets, selectedWalletId },
    } = state;

    const balanceAmount = saleSelectors.balanceSelector(sale);

    const personalization_details = {
      ...(country ? { payor_country: country.a2 } : {}),
      ...SegmentIO.getBaseTaxonomyProps().personalization_details,
    };

    let invoice_status = '';
    if (lastSuccessfulPaymentResponse && lastSuccessfulPaymentResponse.amountPaid) {
      invoice_status =
        lastSuccessfulPaymentResponse.amountPaid >= sale.amount ? 'fully paid' : 'partial Paid';
    }

    return {
      features_enabled: [
        ...(gratuityValue > 0 ? ['tips'] : []),
        ...(isDateScheduled ? ['sp'] : []),
        ...(isAutoPayOn ? ['ap'] : []),
      ],
      ...(invoice_status ? { invoice_status } : {}),
      have_pm_in_wallet:
        userWallets && userWallets.some((wallet) => wallet.id === selectedWalletId),
      payment_method: concatPaymentMethodWithSubType(paymentMethodType, paymentMethodSubType),
      remaining_balance: balanceAmount + gratuityValue - amount,
      schedule_active: isDateScheduled || isAutoPayOn,
      tip_amount: gratuityValue,
      payment_type: SegmentIO.invoice_type,
      personalization_details,
    };
  },

  transactionViewed(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:viewed',
      properties,
    });
  },

  transactionSubmitted(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:submitted:${SegmentIO.invoice_type}_transaction`,
      action: 'submitted',
      ui_object: 'button',
      ui_object_detail: 'pay_button_clicked',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:submitted',
      properties,
    });
  },

  transactionSucceeded(props = {}) {
    const state = getState();
    const transactionId =
      state.payment &&
      state.payment.lastSuccessfulPaymentResponse &&
      state.payment.lastSuccessfulPaymentResponse.trackingId;
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout_confirmation_page',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:succeeded:${SegmentIO.invoice_type}_transaction`,
      action: 'succeeded',
      ui_object: 'page',
      ui_object_detail: 'cp_checkout_confirmation_page',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      transaction_id: transactionId,
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:succeeded',
      properties,
    });
  },

  transactionFailed(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout_confirmation_page',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:failed:${SegmentIO.invoice_type}_transaction`,
      action: 'failed',
      ui_object: 'page',
      ui_object_detail: 'cp_checkout_confirmation_page',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:failed',
      properties,
    });
  },

  transactionEngaged(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      activity_type: 'general_flow',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:engaged:${SegmentIO.invoice_type}_transaction`,
      action: 'engaged',
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:engaged',
      properties,
    });
  },

  transactionCancelled(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      ...props,
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:canceled:${SegmentIO.invoice_type}_transaction`,
      action: 'canceled',
    };

    SegmentIO.track({
      event: 'transaction:canceled',
      properties,
    });
  },

  /**
   * This can be used to send other events that are not covered by the above methods, and needed for specific use cases.
   * @param {string} event
   * @param {Object} props
   */
  sendEvent(event, props = {}) {
    SegmentIO.track({
      event,
      properties: {
        ...SegmentIO.getBaseTaxonomyProps(),
        ...props,
      },
    });
  },

  cardFormFieldClicked(fieldName) {
    const fieldNameMap = {
      number: 'card_number',
      expDate: 'exp_date',
      cvc: 'cvv_code',
      name: 'name_on_card',
      zipCode: 'zip_code',
    };

    SegmentIO.transactionEngaged({
      ui_object: 'form_field',
      ui_object_detail: fieldNameMap[fieldName],
    });
  },

  bankFormFieldClicked(fieldName) {
    const fieldNameMap = {
      bankCode: 'routing_number',
      accountNumber: 'account_number',
      accountNumberConfirm: 'confirm_account_number',
      name: 'account_holders_name',
    };

    SegmentIO.transactionEngaged({
      ui_object: 'form_field',
      ui_object_detail: fieldNameMap[fieldName],
    });
  },

  getCountryFieldActivityType() {
    const state = getState();
    if (!state.payment) {
      return {};
    }
    const {
      payment: { paymentMethodType },
    } = state;
    return paymentMethodType === 'cc' ? 'pm_credit' : 'pm_debit';
  },

  countryFieldClicked() {
    SegmentIO.transactionEngaged({
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: 'payor_country_options',
      ui_access_point: 'transaction_flow',
      activity_type: SegmentIO.getCountryFieldActivityType(),
    });
  },

  countryFieldUpdated(country) {
    SegmentIO.transactionEngaged({
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: `payor_country:${country}`,
      ui_access_point: 'transaction_flow',
      activity_type: SegmentIO.getCountryFieldActivityType(),
    });
  },

  trackWithCommonData(eventName, eventAdditionalPayload = {}) {
    const commonData = SegmentIO.getCommonData();
    const message = {
      event: eventName,
      properties: { ...commonData, ...eventAdditionalPayload },
    };

    SegmentIO.track(message);
  },

  widgetViewed(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|widget:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'widget:viewed',
      properties,
    });
  },

  widgetEngaged(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|widget:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },

  /**
   * Facade for Segment.IO Track
   */
  track({ event, properties }) {
    try {
      typeof window === 'object' &&
        window.analytics &&
        window.analytics.track &&
        window.analytics.track(event, properties, {
          context: { tracking_application_name: properties.tracking_application_name },
        });
    } catch (e) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'track',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: e.stack,
          message: e.message,
        },
      });
    }
  },
  openInvoicesHelpers: {
    getTotalInvoiceAmountView: (openInvoices, balanceAmount) => {
      return openInvoices
        ? openInvoices.reduce(
            (initialValue, invoice) => initialValue + invoice.balance,
            balanceAmount
          )
        : balanceAmount;
    },
    getInvoiceDueDate: (openInvoices, invoiceDueDate) => {
      return openInvoices && openInvoices.length > 0
        ? openInvoices.reduce(
            (initialValue, invoice) => [...initialValue, invoice.dueDate],
            [invoiceDueDate]
          )
        : invoiceDueDate;
    },
    getInvoiceAmount: (openInvoices, amount) => {
      return openInvoices && openInvoices.length > 0
        ? openInvoices.reduce(
            (initialValue, invoice) => [...initialValue, invoice.totalAmt],
            [amount]
          )
        : amount;
    },
  },
};

function deviceType() {
  const mobile =
    typeof window === 'object' && typeof navigator === 'object' && navigator.userAgent
      ? deviceInfo.isMobile(window.navigator.userAgent)
      : null;
  const os =
    typeof window === 'object' && typeof navigator === 'object' && navigator.userAgent
      ? deviceInfo.getOSName(window.navigator.userAgent)
      : null;
  return { mobile, desktop: !mobile, os };
}

function resolution() {
  const resolution = typeof window === 'object' && `${window.innerWidth}x${window.innerHeight}`;
  return { resolution };
}

function generateExpectedAdditionalDataTestNames(ixp) {
  let IXPData = [];
  if (ixp) {
    Object.values(ixp).map((test) => IXPData.push(test.experimentId + '_' + test.treatmentKey));
  }
  return { test_name: IXPData };
}

async function checkIfSegmentScriptLoadError(featureFlags) {
  try {
    const shouldCheckSegmentError = featureFlags['is-log-segmentio-load-error'];

    if (
      // sending splunk log to track failings of segmentIO script loading
      shouldCheckSegmentError &&
      typeof window === 'object'
    ) {
      const errorType = !window.analytics
        ? 'missing script'
        : window.analyticsError
        ? 'analytics error'
        : !window.analytics.track
        ? 'missing track method'
        : false;

      if (errorType) {
        const analyticsError = window.analyticsError || {};
        if (!analyticsError.message) {
          return;
        }
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger },
          event: 'report',
          action: 'catchAnalyticsError',
          activityInfo: {
            status: 'error',
          },
          error: {
            message: JSON.stringify(analyticsError),
          },
        });
      }
    }
  } catch (e) {
    return;
  }
}

export default SegmentIO;
